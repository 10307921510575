
import { defineComponent } from '@nuxtjs/composition-api'

export default defineComponent({
  props: {
    width: { type: String, required: false, default: '32' },
    height: { type: String, required: false, default: '32' },
    ariaHidden: { type: String, required: false, default: 'false' },
    role: { type: String, required: false, default: 'img' },
  },
})
