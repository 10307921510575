
import { defineComponent } from '@nuxtjs/composition-api'
import { setupTranslationPrefixer } from '~/assets/translation'
import filterLabel from '~/mixins/filterLabel'

export default defineComponent({
  mixins: [filterLabel],
  props: {
    title: { type: String, required: true, default: '' },
    titleLang: { type: String, required: false, default: undefined },
    introduction: { type: String, required: false, default: undefined },
    introductionLang: { type: String, required: false, default: undefined },
    image: { type: Object, required: false, default: undefined },
    breadcrumbs: { type: Array, required: false, default: undefined },
    tags: { type: Array, required: false, default: undefined },
    authors: { type: Array, required: false, default: undefined },
    experts: { type: Array, required: false, default: undefined },
    postDate: { type: String, required: false, default: undefined },
    eventDate: { type: String, required: false, default: undefined },
    eventDateEnd: { type: String, required: false, default: undefined },
    eventTypes: { type: Array, required: false, default: undefined },
    materialType: { type: String, required: false, default: undefined },
  },
  setup() {
    return {
      ...setupTranslationPrefixer('page-header'),
    }
  },
  data() {
    return {
      headerInited: false,
    }
  },
  mounted() {
    this.headerInited = true
  },
})
