import xor from 'lodash/xor'

export const state = () => ({
  enabled: [],
})

export const getters = {
  isEnabled:
    (state) =>
    (name = 'control-panel') =>
      state.enabled.includes(name),
}

export const mutations = {
  reset: (state) => (state.enabled = []),
  toggle: (state, name = 'control-panel') => (state.enabled = xor(state.enabled, [name])),
  enable: (state, name = 'control-panel') => {
    if (!state.enabled.includes(name)) {
      state.enabled = xor(state.enabled, [name])
    }
  },
}
