export const state = () => {
  return {
    keyword: '',
    page: 1,

    // Search results
    hits: [],
    results: [],
    totalHits: 0,
  }
}

export const getters = {
  // Constants
  hitsPerPage: () => 15,

  // Search context
  keyword: (state) => state.keyword,
  page: (state) => state.page,

  // Url parameters
  urlParameters: (state) => ({
    keyword: state.keyword || undefined,
    page: state.page === 1 ? undefined : state.page,
  }),

  // Search results
  hits: (state) => state.hits,
  results: (state) => state.results,
  totalHits: (state) => state.totalHits,
}

export const mutations = {
  // Initialize
  initialize: (state, { keyword, page }) => {
    state.keyword = typeof keyword === 'string' ? keyword : ''
    state.page = typeof page === 'string' ? Math.max(1, Number.parseInt(page)) : 1
  },

  // Search context
  reset(state) {
    state.keyword = ''
    state.page = 1
    this.dispatch('generic-search/fetch')
  },
  setKeyword(state, keyword) {
    state.keyword = keyword
    state.page = 1
    this.dispatch('generic-search/fetch')
  },
  setPage(state, page) {
    state.page = Math.max(1, page)
    this.dispatch('generic-search/fetch')
  },

  // Search results
  setHits: (state, hits) => (state.hits = hits),
  setResults: (state, results) => (state.results = results),
  setTotalHits: (state, totalHits) => (state.totalHits = totalHits),
}

export const actions = {
  async fetch({ commit, dispatch, getters }) {
    const { hitsPerPage, keyword, page } = getters
    const key = `generic-search;${this.$i18n.locale};keyword:${keyword}`
    const ttl = 300

    const fetcher = async () => {
      if (!keyword) {
        return { hits: [], totalHits: 0 }
      }

      // Fetch 1024 results, therefore we will not need to fetch new results when we switch pages
      const { elasticSearch } = await this.$graphqlFetch({
        token: 'elasticsearch',
        query: `query search($siteHandle:[ElasticSiteHandle], $sectionHandle:[ElasticSectionHandle], $query:String) {
          elasticSearch(siteHandle:$siteHandle, sectionHandle:$sectionHandle, query:$query) {
            count
            hits(limit:1024) {
             uri
            }
          }
        }`,
        variables: {
          query: keyword,
          sectionHandle: '_all_',
          siteHandle: `${this.$i18n.locale}Default`,
        },
      })

      return { hits: elasticSearch.hits.map(({ uri }) => uri), totalHits: elasticSearch.count }
    }

    // Fetch the hits
    const { hits, totalHits } = await dispatch('cache/fetch', { key, ttl, fetcher, fallback: { hits: [], totalHits: 0 } }, { root: true })
    commit('setHits', hits)
    commit('setTotalHits', totalHits)

    // Initialize the series list with N empty elements, used to trigger loaders
    const slice = hits.slice((page - 1) * hitsPerPage, page * hitsPerPage)

    // Fetch all associated entries within the current page
    const results = await Promise.all(slice.map((uri) => dispatch('page/fetchUri', { uri }, { root: true })))
    commit(
      'setResults',
      results.filter((result) => result)
    )
    return hits
  },
}
