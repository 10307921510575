
import { defineComponent } from '@nuxtjs/composition-api'

export default defineComponent({
  filters: {
    replace(st, rep, repWith) {
      if (typeof st !== 'string') {
        return st
      }
      return st.split(rep).join(repWith)
    },
  },
  props: {
    text: { type: [String, Object], required: true },
    // Heading size (influences applied styling), e.g. 0, 1, 2, 3, 4.
    size: { type: Number, required: true, validator: (val) => val >= 0 && val <= 4 },
    // Heading HTML semantic: sets used HTML tag (e.g. H1, H2, H3, H4, H5, H6).
    semantic: { type: Number, required: false, default: undefined, validator: (val) => val >= 1 && val <= 6 },
    textColor: { type: [String, Object], required: false, default: undefined },
    margins: { type: Array, required: false, default: undefined },
    lang: { type: String, required: false, default: undefined },
  },
  computed: {
    tag() {
      return 'h' + String(this.semantic !== undefined ? this.semantic : this.size === 0 ? 1 : this.size)
    },
    hasSlot() {
      return !!this.$slots.default
    },
  },
})
