import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _25dafb54 = () => interopDefault(import('../pages/app-health-check.vue' /* webpackChunkName: "pages/app-health-check" */))
const _535f22e2 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _7fbe5c01 = () => interopDefault(import('../pages/styleguide/index.vue' /* webpackChunkName: "pages/styleguide/index" */))
const _c236de6e = () => interopDefault(import('../pages/debug/link.vue' /* webpackChunkName: "pages/debug/link" */))
const _07bfc28c = () => interopDefault(import('../pages/admin.vue' /* webpackChunkName: "pages/admin" */))
const _10a4f970 = () => interopDefault(import('../pages/styleguide/buttons.vue' /* webpackChunkName: "pages/styleguide/buttons" */))
const _bb5f2bdc = () => interopDefault(import('../pages/styleguide/cards.vue' /* webpackChunkName: "pages/styleguide/cards" */))
const _c7e19d5e = () => interopDefault(import('../pages/styleguide/colors.vue' /* webpackChunkName: "pages/styleguide/colors" */))
const _ba5dc808 = () => interopDefault(import('../pages/styleguide/footer.vue' /* webpackChunkName: "pages/styleguide/footer" */))
const _77f90024 = () => interopDefault(import('../pages/styleguide/header.vue' /* webpackChunkName: "pages/styleguide/header" */))
const _5e0b2249 = () => interopDefault(import('../pages/styleguide/icons.vue' /* webpackChunkName: "pages/styleguide/icons" */))
const _4c5309bc = () => interopDefault(import('../pages/styleguide/matrix.vue' /* webpackChunkName: "pages/styleguide/matrix" */))
const _2ec2d080 = () => interopDefault(import('../pages/styleguide/temporary-content-page.vue' /* webpackChunkName: "pages/styleguide/temporary-content-page" */))
const _b54cf65c = () => interopDefault(import('../pages/styleguide/theming-example.vue' /* webpackChunkName: "pages/styleguide/theming-example" */))
const _6f2f2510 = () => interopDefault(import('../pages/styleguide/typography.vue' /* webpackChunkName: "pages/styleguide/typography" */))
const _13918ebc = () => interopDefault(import('../pages/styleguide/typography-content.vue' /* webpackChunkName: "pages/styleguide/typography-content" */))
const _2aad7222 = () => interopDefault(import('../pages/styleguide/visuals.vue' /* webpackChunkName: "pages/styleguide/visuals" */))
const _e87adbc8 = () => interopDefault(import('../pages/_.vue' /* webpackChunkName: "pages/_" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/app-health-check",
    component: _25dafb54,
    pathToRegexpOptions: {"strict":true},
    name: "app-health-check"
  }, {
    path: "/en",
    component: _535f22e2,
    pathToRegexpOptions: {"strict":true},
    name: "index___en"
  }, {
    path: "/en-GB",
    component: _535f22e2,
    pathToRegexpOptions: {"strict":true},
    name: "index___en-GB"
  }, {
    path: "/styleguide",
    component: _7fbe5c01,
    pathToRegexpOptions: {"strict":true},
    name: "styleguide___nl"
  }, {
    path: "/debug/link",
    component: _c236de6e,
    pathToRegexpOptions: {"strict":true},
    name: "debug-link___nl"
  }, {
    path: "/en-GB/admin",
    component: _07bfc28c,
    pathToRegexpOptions: {"strict":true},
    name: "admin___en-GB"
  }, {
    path: "/en-GB/styleguide",
    component: _7fbe5c01,
    pathToRegexpOptions: {"strict":true},
    name: "styleguide___en-GB"
  }, {
    path: "/en/styleguide",
    component: _7fbe5c01,
    pathToRegexpOptions: {"strict":true},
    name: "styleguide___en"
  }, {
    path: "/styleguide/buttons",
    component: _10a4f970,
    pathToRegexpOptions: {"strict":true},
    name: "styleguide-buttons___nl"
  }, {
    path: "/styleguide/cards",
    component: _bb5f2bdc,
    pathToRegexpOptions: {"strict":true},
    name: "styleguide-cards___nl"
  }, {
    path: "/styleguide/colors",
    component: _c7e19d5e,
    pathToRegexpOptions: {"strict":true},
    name: "styleguide-colors___nl"
  }, {
    path: "/styleguide/footer",
    component: _ba5dc808,
    pathToRegexpOptions: {"strict":true},
    name: "styleguide-footer___nl"
  }, {
    path: "/styleguide/header",
    component: _77f90024,
    pathToRegexpOptions: {"strict":true},
    name: "styleguide-header___nl"
  }, {
    path: "/styleguide/icons",
    component: _5e0b2249,
    pathToRegexpOptions: {"strict":true},
    name: "styleguide-icons___nl"
  }, {
    path: "/styleguide/matrix",
    component: _4c5309bc,
    pathToRegexpOptions: {"strict":true},
    name: "styleguide-matrix___nl"
  }, {
    path: "/styleguide/temporary-content-page",
    component: _2ec2d080,
    pathToRegexpOptions: {"strict":true},
    name: "styleguide-temporary-content-page___nl"
  }, {
    path: "/styleguide/theming-example",
    component: _b54cf65c,
    pathToRegexpOptions: {"strict":true},
    name: "styleguide-theming-example___nl"
  }, {
    path: "/styleguide/typography",
    component: _6f2f2510,
    pathToRegexpOptions: {"strict":true},
    name: "styleguide-typography___nl"
  }, {
    path: "/styleguide/typography-content",
    component: _13918ebc,
    pathToRegexpOptions: {"strict":true},
    name: "styleguide-typography-content___nl"
  }, {
    path: "/styleguide/visuals",
    component: _2aad7222,
    pathToRegexpOptions: {"strict":true},
    name: "styleguide-visuals___nl"
  }, {
    path: "/en-GB/debug/link",
    component: _c236de6e,
    pathToRegexpOptions: {"strict":true},
    name: "debug-link___en-GB"
  }, {
    path: "/en-GB/styleguide/buttons",
    component: _10a4f970,
    pathToRegexpOptions: {"strict":true},
    name: "styleguide-buttons___en-GB"
  }, {
    path: "/en-GB/styleguide/cards",
    component: _bb5f2bdc,
    pathToRegexpOptions: {"strict":true},
    name: "styleguide-cards___en-GB"
  }, {
    path: "/en-GB/styleguide/colors",
    component: _c7e19d5e,
    pathToRegexpOptions: {"strict":true},
    name: "styleguide-colors___en-GB"
  }, {
    path: "/en-GB/styleguide/footer",
    component: _ba5dc808,
    pathToRegexpOptions: {"strict":true},
    name: "styleguide-footer___en-GB"
  }, {
    path: "/en-GB/styleguide/header",
    component: _77f90024,
    pathToRegexpOptions: {"strict":true},
    name: "styleguide-header___en-GB"
  }, {
    path: "/en-GB/styleguide/icons",
    component: _5e0b2249,
    pathToRegexpOptions: {"strict":true},
    name: "styleguide-icons___en-GB"
  }, {
    path: "/en-GB/styleguide/matrix",
    component: _4c5309bc,
    pathToRegexpOptions: {"strict":true},
    name: "styleguide-matrix___en-GB"
  }, {
    path: "/en-GB/styleguide/temporary-content-page",
    component: _2ec2d080,
    pathToRegexpOptions: {"strict":true},
    name: "styleguide-temporary-content-page___en-GB"
  }, {
    path: "/en-GB/styleguide/theming-example",
    component: _b54cf65c,
    pathToRegexpOptions: {"strict":true},
    name: "styleguide-theming-example___en-GB"
  }, {
    path: "/en-GB/styleguide/typography",
    component: _6f2f2510,
    pathToRegexpOptions: {"strict":true},
    name: "styleguide-typography___en-GB"
  }, {
    path: "/en-GB/styleguide/typography-content",
    component: _13918ebc,
    pathToRegexpOptions: {"strict":true},
    name: "styleguide-typography-content___en-GB"
  }, {
    path: "/en-GB/styleguide/visuals",
    component: _2aad7222,
    pathToRegexpOptions: {"strict":true},
    name: "styleguide-visuals___en-GB"
  }, {
    path: "/en/debug/link",
    component: _c236de6e,
    pathToRegexpOptions: {"strict":true},
    name: "debug-link___en"
  }, {
    path: "/en/styleguide/buttons",
    component: _10a4f970,
    pathToRegexpOptions: {"strict":true},
    name: "styleguide-buttons___en"
  }, {
    path: "/en/styleguide/cards",
    component: _bb5f2bdc,
    pathToRegexpOptions: {"strict":true},
    name: "styleguide-cards___en"
  }, {
    path: "/en/styleguide/colors",
    component: _c7e19d5e,
    pathToRegexpOptions: {"strict":true},
    name: "styleguide-colors___en"
  }, {
    path: "/en/styleguide/footer",
    component: _ba5dc808,
    pathToRegexpOptions: {"strict":true},
    name: "styleguide-footer___en"
  }, {
    path: "/en/styleguide/header",
    component: _77f90024,
    pathToRegexpOptions: {"strict":true},
    name: "styleguide-header___en"
  }, {
    path: "/en/styleguide/icons",
    component: _5e0b2249,
    pathToRegexpOptions: {"strict":true},
    name: "styleguide-icons___en"
  }, {
    path: "/en/styleguide/matrix",
    component: _4c5309bc,
    pathToRegexpOptions: {"strict":true},
    name: "styleguide-matrix___en"
  }, {
    path: "/en/styleguide/temporary-content-page",
    component: _2ec2d080,
    pathToRegexpOptions: {"strict":true},
    name: "styleguide-temporary-content-page___en"
  }, {
    path: "/en/styleguide/theming-example",
    component: _b54cf65c,
    pathToRegexpOptions: {"strict":true},
    name: "styleguide-theming-example___en"
  }, {
    path: "/en/styleguide/typography",
    component: _6f2f2510,
    pathToRegexpOptions: {"strict":true},
    name: "styleguide-typography___en"
  }, {
    path: "/en/styleguide/typography-content",
    component: _13918ebc,
    pathToRegexpOptions: {"strict":true},
    name: "styleguide-typography-content___en"
  }, {
    path: "/en/styleguide/visuals",
    component: _2aad7222,
    pathToRegexpOptions: {"strict":true},
    name: "styleguide-visuals___en"
  }, {
    path: "/",
    component: _535f22e2,
    pathToRegexpOptions: {"strict":true},
    name: "index___nl"
  }, {
    path: "/en/admin/(.*)?",
    component: _07bfc28c,
    pathToRegexpOptions: {"strict":true},
    name: "admin___en"
  }, {
    path: "/admin/(.*)?",
    component: _07bfc28c,
    pathToRegexpOptions: {"strict":true},
    name: "admin___nl"
  }, {
    path: "/en-GB/*",
    component: _e87adbc8,
    pathToRegexpOptions: {"strict":true},
    name: "all___en-GB"
  }, {
    path: "/en/*",
    component: _e87adbc8,
    pathToRegexpOptions: {"strict":true},
    name: "all___en"
  }, {
    path: "/*",
    component: _e87adbc8,
    pathToRegexpOptions: {"strict":true},
    name: "all___nl"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
