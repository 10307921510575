import { defineNuxtPlugin } from '@nuxtjs/composition-api'

export function hasPiwik($config) {
  return 'piwikWebsiteId' in $config && $config.piwikWebsiteId !== undefined
}

export function openPiwikConsentForm() {
  if (process.client && window.ppms !== undefined) {
    window.ppms.cm.api('openConsentForm')
  }
}

function injectPiwikScript(piwikWebsiteId) {
  if (document === undefined) {
    return
  }

  const piwikBaseUrl = 'https://deltares-analytics.containers.piwik.pro'

  const link = document.createElement('link')
  link.rel = 'preconnect'
  link.href = piwikBaseUrl

  const script = document.createElement('script')
  script.type = 'text/javascript'
  script.setAttribute('hid', 'piwik')
  script.innerHTML = `(function(window, document, dataLayerName, id) {
    window[dataLayerName]=window[dataLayerName]||[],window[dataLayerName].push({start:(new Date).getTime(),event:"stg.start"});var scripts=document.getElementsByTagName('script')[0],tags=document.createElement('script');
    function stgCreateCookie(a,b,c){var d="";if(c){var e=new Date;e.setTime(e.getTime()+24*c*60*60*1e3),d="; expires="+e.toUTCString()}document.cookie=a+"="+b+d+"; path=/"}
    var isStgDebug=(window.location.href.match("stg_debug")||document.cookie.match("stg_debug"))&&!window.location.href.match("stg_disable_debug");stgCreateCookie("stg_debug",isStgDebug?1:"",isStgDebug?14:-1);
    var qP=[];dataLayerName!=="dataLayer"&&qP.push("data_layer_name="+dataLayerName),isStgDebug&&qP.push("stg_debug");var qPString=qP.length>0?("?"+qP.join("&")):"";
    tags.async=!0,tags.src="${piwikBaseUrl}/"+id+".js"+qPString,scripts.parentNode.insertBefore(tags,scripts);
    !function(a,n,i){a[n]=a[n]||{};for(var c=0;c<i.length;c++)!function(i){a[n][i]=a[n][i]||{},a[n][i].api=a[n][i].api||function(){var a=[].slice.call(arguments,0);"string"==typeof a[0]&&window[dataLayerName].push({event:n+"."+i+":"+a[0],parameters:[].slice.call(arguments,1)})}}(i[c])}(window,"ppms",["tm","cm"]);
    })(window, document, 'dataLayer', '${piwikWebsiteId}');`

  document.head.append(link)
  document.body.prepend(script)
}

function getCompliance(store) {
  if (process.client) {
    const handler = () => {
      if (window.ppms === undefined) {
        return
      }

      // https://developers.piwik.pro/en/latest/consent_manager/js_api/index.html
      window.ppms.cm.api('getComplianceSettings', (settings) => {
        store.commit('piwik/setCompliance', settings)
      })
    }
    // Keep polling the compliance settings (unfortunately, the API does not provide listening to an event or registering a callback).
    window.setInterval(handler, 1000)
    handler()
  }
}

export default defineNuxtPlugin(({ $config, store }) => {
  if (hasPiwik($config)) {
    injectPiwikScript($config.piwikWebsiteId)
    getCompliance(store)
  }
})
