import { useContext } from '@nuxtjs/composition-api'

export async function loadCombinedTranslations(context, locale) {
  const response = await (await fetch(`${context.$config.cmsSiteUrl}/api/${locale}/translations.json`)).json()
  return {
    ...flatten(importStaticMessages(locale), '', {}),
    ...Object.fromEntries(response.data.map((entry) => [entry.key, entry.value])),
  }
}

export function setupTranslationPrefixer(componentName, groupName = 'default') {
  const { i18n } = useContext()
  const componentName_ = kebabCase(componentName)
  const groupName_ = kebabCase(groupName)
  const localeMapForDate = { en: 'en-GB' }
  return {
    translationComponent_: componentName_,
    translationGroup_: groupName_,

    // Helper functions that prefix and normalize the translation key
    k_: (key) => key && [componentName_, groupName_, kebabCase(key)].join('.'),
    t_: (key, ...args) => key && i18n?.t([componentName_, groupName_, kebabCase(key)].join('.'), ...args),
    tc_: (key, ...args) => key && i18n?.tc([componentName_, groupName_, kebabCase(key)].join('.'), ...args),
    te_: (key, ...args) => key && i18n?.te([componentName_, groupName_, kebabCase(key)].join('.'), ...args),

    // Helper function that automatically converts strings into Date objects
    // Using `localeMapForDate` to get `en-GB` British dates when the locale is `en`.
    d_: (value, pattern) => (value ? i18n?.d(typeof value === 'object' ? value : new Date(value), pattern, localeMapForDate[i18n?.locale] || i18n?.locale) : ''),
  }
}

export function flatten(object, prefix, result) {
  for (const [key, value] of Object.entries(object)) {
    const name = prefix ? `${prefix}.${key}` : key
    if (typeof value === 'object') {
      flatten(value, name, result)
    } else {
      result[name] = value
    }
  }
  return result
}

export function importStaticMessages(locale) {
  switch (locale) {
    case 'en':
      return require('./translations/en.json')
    case 'nl':
      return require('./translations/nl.json')
    default:
      return {}
  }
}

function kebabCase(str) {
  return str
    .replace(/_/gu, '-')
    .replace(/\B([A-Z])/gu, '-$1')
    .toLowerCase()
}
