
import { defineComponent } from '@nuxtjs/composition-api'
import { mapGetters } from 'vuex'
import { setupTranslationPrefixer } from '~/assets/translation'

export default defineComponent({
  setup() {
    return {
      ...setupTranslationPrefixer('footer'),
    }
  },
  computed: {
    ...mapGetters({
      footer: 'navigation/footer',
      footerService: 'navigation/footerService',
    }),
    locale() {
      return this.$i18n.locale
    },
  },
})
