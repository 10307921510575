import camelCase from 'lodash/camelCase'
import upperFirst from 'lodash/upperFirst'

export const COOKIE_CONSENT_UNDECIDED = 'undecided'
export const COOKIE_CONSENT_REJECTED = 'rejected'
export const COOKIE_CONSENT_APPROVED = 'approved'
export const COOKIE_CONSENT_UNDEFINED = undefined

export const state = () => {
  return {
    complianceAnalytics: undefined,
    complianceCustomConsent: undefined,
  }
}

export const getters = {
  compliance: (state) => (type) => {
    const name = 'compliance' + upperFirst(camelCase(type))
    if (!(name in state)) {
      return COOKIE_CONSENT_UNDEFINED
    }
    const consent = state[name]

    // -1 - user has not interacted, e.g. has closed a consent popup without any decision
    // 0 - user reject consent
    // 1 - user approve consent
    switch (consent) {
      case -1:
        return COOKIE_CONSENT_UNDECIDED
      case 0:
        return COOKIE_CONSENT_REJECTED
      case 1:
        return COOKIE_CONSENT_APPROVED
      default:
        return COOKIE_CONSENT_UNDEFINED
    }
  },
}

export const mutations = {
  setCompliance: (state, compliance) => {
    if (!('consents' in compliance) || typeof compliance.consents !== 'object') {
      return
    }

    for (const [key, consent] of Object.entries(compliance.consents)) {
      const name = 'compliance' + upperFirst(camelCase(key))
      if (name in state && 'status' in consent) {
        state[name] = consent.status
      }
    }
  },
}
