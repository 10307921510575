
import { setupTranslationPrefixer } from '~/assets/translation'

export default {
  props: {
    breadcrumbs: { type: Array, required: true },
    pageTitle: { type: String, required: true },
  },
  setup() {
    return {
      ...setupTranslationPrefixer('breadcrumbs'),
    }
  },
}
