function explodeUrl(url, rewriteMap) {
  const { protocol, host, rest } = url.match(/^(?<protocol>[^/:]+:)?(\/\/(?<host>[^:/]+))?(:(?<port>[0-9]+))?(?<rest>.*$)/i).groups
  const replacedHost = rewriteMap[host] || host || ''
  const type = [undefined, 'http:', 'https:'].includes(protocol) ? 'web' : 'other'
  return { url: [type === 'web' ? (replacedHost ? '//' : '') : protocol, replacedHost, rest].join(''), host: replacedHost, protocol, rest, type }
}

function getDistance(sourceHost, targetHost) {
  if (!sourceHost || !targetHost) {
    return 0
  }

  const sourceParts = sourceHost.split('.').reverse()
  const targetParts = targetHost.split('.').reverse()

  if (sourceParts?.[0] !== targetParts?.[0]) {
    return -1
  }
  if (!(sourceParts?.[0] === 'localhost' || targetParts?.[0] === 'localhost') && sourceParts?.[1] !== targetParts?.[1]) {
    return -1
  }

  for (let index = 0; index < sourceParts.length; index++) {
    if (sourceParts[index] !== targetParts[index]) {
      return Math.max(sourceParts.length, targetParts.length) - index
    }
  }
  return 0
}

/**
 * Provides information on a link
 *
 * Returns { distance, scope, type, url }
 *
 * Distance: is a number indicating how far the source and target host are from each other
 * For example, from www.example.com to subdomain.example.com have distance 1
 *
 * Scope: is either 'inside' or 'outside'
 * For example, from www.example.com to subdomain.example.com is 'inside'
 * and from www.example.com to www.zicht.nl is 'outside'
 *
 * Type: is either 'web' or 'other'
 * For example, to http://www.example.com/bar is 'web' and to mailto:test@example.com is 'other'
 *
 * Url: is the the reformatted target url
 *
 * Example where the link stays on example.com
 * > linkDetails('//example.com/foo', '//www.example.com/bar', { rewrites:[['www.example.com', 'example.com']] })
 * > --> { distance: 1, scope: 'inside', type: 'web', url: '/bar' }
 *
 * Example where the link goes to a completely different zicht.nl
 * > linkDetails('//example.com/foo', '//www.zicht.nl/bar')
 * > --> { distance: -1, scope: 'outside', type: 'web', url: '//www.zicht.nl/bar' }
 *
 * @todo consider moving this code into a library for better re-usability
 */
export function linkDetails(sourceUrl, targetUrl, options) {
  // Convert lists of rewrites into a map
  // [['www.example.com', 'example.com']] -> {'www.example.com': 'www.example.com', 'example.com': 'www.example.com'}
  const rewriteMap = Object.fromEntries((options?.rewrites || []).flatMap((rewrites) => rewrites.map((rewrite) => [rewrite, rewrites[0]])))

  // Explode the url into its parts, and perform possible rewrites
  const source = explodeUrl(sourceUrl || '', rewriteMap)
  const target = explodeUrl(targetUrl || '', rewriteMap)

  // The link `type` determines whether it links to a web page or something else, i.e. an email link
  const type = target.type

  // The link `scope` determines whether it links to `inside` or `outside` the app
  // const scope = type === 'web' && [undefined, source.host].includes(target.host) ? 'inside' : 'outside'
  const scope = type === 'web' && (source.host === '' || ['', source.host].includes(target.host)) ? 'inside' : 'outside'

  // The link `distance` is the distance between source and target host, i.e. example.com and subdomain.example.com have distance `1`
  const distance = type === 'web' ? (scope === 'inside' ? 0 : getDistance(source.host, target.host)) : -1

  // The link `url` is a relative url when `inside` the app or otherwise an absolute url
  const url = scope === 'inside' ? target.rest : target.url

  return { distance, scope, type, url }
}

export function removeTrailingSlash(url) {
  return url.replace(/\/$/, '')
}
