function formatForTranslationKey(str) {
  if (str.match(/^[A-Z]+$/) !== null) {
    return str.toLowerCase()
  }
  return str
    .replace(/[^A-z0-9]+/g, '-')
    .replace(/\B([A-Z])/gu, '-$1')
    .replace(/^-+/, '')
    .replace(/-+$/, '')
    .toLowerCase()
}

export default {
  methods: {
    filterLabel(filterName, filterLabel) {
      const key = `facets.${formatForTranslationKey(filterName)}.${formatForTranslationKey(filterLabel)}`
      return this.$i18n.te(key) ? this.$i18n.t(key) : filterLabel
    },
  },
}
