
import { defineComponent, ref, useFetch, useStore } from '@nuxtjs/composition-api'

export default defineComponent({
  layout: 'error',
  props: {
    error: { type: Object, required: false, default: undefined },
  },
  setup(props) {
    // eslint-disable-next-line no-console
    console.error('Error', props.error)

    const store = useStore()
    const page = ref()
    useFetch(async () => {
      page.value = await store.dispatch('page/fetchError', { ...props.error, push: true })
    })

    return { page }
  },
})
