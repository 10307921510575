
export default {
  props: {
    items: { type: Array, required: true },
    pageType: { type: String, required: false, default: 'genericPage' },
  },
  data() {
    return {
      componentMap: {
        banner: 'MatrixBanner',
        cookiesConsent: 'MatrixCookiesConsent',
        employeeSpotlight: 'MatrixEmployeeSpotlight',
        form: 'MatrixForm',
        imageLarge: 'MatrixImageLarge',
        linkList: 'MatrixLinkList',
        overviewItems: 'MatrixOverview',
        projectSpotlight: 'MatrixProjectSpotlight',
        relatedPages: 'MatrixRelatedMinimal',
        stories: 'MatrixStories',
        table: 'MatrixTable',
        text: 'MatrixText',
        video: 'MatrixVideo',
      },
    }
  },
  methods: {
    itemProps(item) {
      // By destructing the item, we can extract `type` and all the rest into consts
      // and therefor have them removed from itemWithoutType (we don't want to bind `type="text"` to the components)
      const { type, ...itemWithoutType } = item
      return itemWithoutType
    },
  },
}
