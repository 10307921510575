
import { defineComponent, ref, computed } from '@nuxtjs/composition-api'
import { getMediaImage } from '~/assets/media'

export default defineComponent({
  props: {
    image: { type: Object, required: false, default: undefined },
    imgClass: { type: String, required: false, default: '' },
    alt: { type: String, required: false, default: undefined },
    lazy: { type: Boolean, required: false, default: true },
    heightAuto: { type: Boolean, required: false, default: false },
    caption: { type: String, required: false, default: '' },
    captionClass: { type: String, required: false, default: '' },
    copyright: { type: String, required: false, default: '' },
    copyrightClass: { type: String, required: false, default: '' },
    hasLazyLoad: { type: Boolean, required: false, default: false },
  },
  setup(props) {
    const loading = ref(true)
    const media = computed(() => {
      return getMediaImage(props.image)
    })
    const stopLoading = () => {
      loading.value = false
    }

    return {
      stopLoading,
      loading,
      media,
      source: props.image?.source,
    }
  },
})
