
import { computed, defineComponent } from '@nuxtjs/composition-api'

const validIcons = ['arrow-right', 'dot', 'external']

export default defineComponent({
  props: {
    icon: { type: String, required: true, default: 'arrow-right', validator: (value) => validIcons.includes(value) },
    height: { type: String, required: false, default: '32' },
    width: { type: String, required: false, default: '32' },
  },
  setup(props) {
    const capitalize = (string) => {
      return string.charAt(0).toUpperCase() + string.slice(1)
    }

    const toCamelCase = (string) => {
      return string.replace(/-([a-z])/g, (match) => {
        return match[1].toUpperCase()
      })
    }

    const componentName = computed(() => {
      return `Icon${capitalize(toCamelCase(props.icon))}`
    })

    return { componentName }
  },
})
