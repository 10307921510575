export const AppFormulateLabel = () => import('../../components/Atom/AppFormulateLabel.vue' /* webpackChunkName: "components/app-formulate-label" */).then(c => wrapFunctional(c.default || c))
export const Icon = () => import('../../components/Atom/Icon.vue' /* webpackChunkName: "components/icon" */).then(c => wrapFunctional(c.default || c))
export const Link = () => import('../../components/Atom/Link.vue' /* webpackChunkName: "components/link" */).then(c => wrapFunctional(c.default || c))
export const WorldMap = () => import('../../components/Atom/WorldMap.vue' /* webpackChunkName: "components/world-map" */).then(c => wrapFunctional(c.default || c))
export const BrandLogoDeltaLife = () => import('../../components/Atom/Brand/LogoDeltaLife.vue' /* webpackChunkName: "components/brand-logo-delta-life" */).then(c => wrapFunctional(c.default || c))
export const BrandLogoDeltares = () => import('../../components/Atom/Brand/LogoDeltares.vue' /* webpackChunkName: "components/brand-logo-deltares" */).then(c => wrapFunctional(c.default || c))
export const IconArrowDown = () => import('../../components/Atom/Icon/IconArrowDown.vue' /* webpackChunkName: "components/icon-arrow-down" */).then(c => wrapFunctional(c.default || c))
export const IconArrowRight = () => import('../../components/Atom/Icon/IconArrowRight.vue' /* webpackChunkName: "components/icon-arrow-right" */).then(c => wrapFunctional(c.default || c))
export const IconArrowUp = () => import('../../components/Atom/Icon/IconArrowUp.vue' /* webpackChunkName: "components/icon-arrow-up" */).then(c => wrapFunctional(c.default || c))
export const IconCheckbox = () => import('../../components/Atom/Icon/IconCheckbox.vue' /* webpackChunkName: "components/icon-checkbox" */).then(c => wrapFunctional(c.default || c))
export const IconCheckboxChecked = () => import('../../components/Atom/Icon/IconCheckboxChecked.vue' /* webpackChunkName: "components/icon-checkbox-checked" */).then(c => wrapFunctional(c.default || c))
export const IconChecked = () => import('../../components/Atom/Icon/IconChecked.vue' /* webpackChunkName: "components/icon-checked" */).then(c => wrapFunctional(c.default || c))
export const IconCheckmark = () => import('../../components/Atom/Icon/IconCheckmark.vue' /* webpackChunkName: "components/icon-checkmark" */).then(c => wrapFunctional(c.default || c))
export const IconChevronDown = () => import('../../components/Atom/Icon/IconChevronDown.vue' /* webpackChunkName: "components/icon-chevron-down" */).then(c => wrapFunctional(c.default || c))
export const IconChevronLeft = () => import('../../components/Atom/Icon/IconChevronLeft.vue' /* webpackChunkName: "components/icon-chevron-left" */).then(c => wrapFunctional(c.default || c))
export const IconChevronRight = () => import('../../components/Atom/Icon/IconChevronRight.vue' /* webpackChunkName: "components/icon-chevron-right" */).then(c => wrapFunctional(c.default || c))
export const IconChevronUp = () => import('../../components/Atom/Icon/IconChevronUp.vue' /* webpackChunkName: "components/icon-chevron-up" */).then(c => wrapFunctional(c.default || c))
export const IconCross = () => import('../../components/Atom/Icon/IconCross.vue' /* webpackChunkName: "components/icon-cross" */).then(c => wrapFunctional(c.default || c))
export const IconCrossCircled = () => import('../../components/Atom/Icon/IconCrossCircled.vue' /* webpackChunkName: "components/icon-cross-circled" */).then(c => wrapFunctional(c.default || c))
export const IconDot = () => import('../../components/Atom/Icon/IconDot.vue' /* webpackChunkName: "components/icon-dot" */).then(c => wrapFunctional(c.default || c))
export const IconDownload = () => import('../../components/Atom/Icon/IconDownload.vue' /* webpackChunkName: "components/icon-download" */).then(c => wrapFunctional(c.default || c))
export const IconEnlarge = () => import('../../components/Atom/Icon/IconEnlarge.vue' /* webpackChunkName: "components/icon-enlarge" */).then(c => wrapFunctional(c.default || c))
export const IconExternal = () => import('../../components/Atom/Icon/IconExternal.vue' /* webpackChunkName: "components/icon-external" */).then(c => wrapFunctional(c.default || c))
export const IconFacebook = () => import('../../components/Atom/Icon/IconFacebook.vue' /* webpackChunkName: "components/icon-facebook" */).then(c => wrapFunctional(c.default || c))
export const IconFacebookOutline = () => import('../../components/Atom/Icon/IconFacebookOutline.vue' /* webpackChunkName: "components/icon-facebook-outline" */).then(c => wrapFunctional(c.default || c))
export const IconFilter = () => import('../../components/Atom/Icon/IconFilter.vue' /* webpackChunkName: "components/icon-filter" */).then(c => wrapFunctional(c.default || c))
export const IconGlobe = () => import('../../components/Atom/Icon/IconGlobe.vue' /* webpackChunkName: "components/icon-globe" */).then(c => wrapFunctional(c.default || c))
export const IconInstagram = () => import('../../components/Atom/Icon/IconInstagram.vue' /* webpackChunkName: "components/icon-instagram" */).then(c => wrapFunctional(c.default || c))
export const IconInstagramOutline = () => import('../../components/Atom/Icon/IconInstagramOutline.vue' /* webpackChunkName: "components/icon-instagram-outline" */).then(c => wrapFunctional(c.default || c))
export const IconLinkedin = () => import('../../components/Atom/Icon/IconLinkedin.vue' /* webpackChunkName: "components/icon-linkedin" */).then(c => wrapFunctional(c.default || c))
export const IconLinkedinOutline = () => import('../../components/Atom/Icon/IconLinkedinOutline.vue' /* webpackChunkName: "components/icon-linkedin-outline" */).then(c => wrapFunctional(c.default || c))
export const IconLoader = () => import('../../components/Atom/Icon/IconLoader.vue' /* webpackChunkName: "components/icon-loader" */).then(c => wrapFunctional(c.default || c))
export const IconMagnifier = () => import('../../components/Atom/Icon/IconMagnifier.vue' /* webpackChunkName: "components/icon-magnifier" */).then(c => wrapFunctional(c.default || c))
export const IconMagnifierLarge = () => import('../../components/Atom/Icon/IconMagnifierLarge.vue' /* webpackChunkName: "components/icon-magnifier-large" */).then(c => wrapFunctional(c.default || c))
export const IconPencil = () => import('../../components/Atom/Icon/IconPencil.vue' /* webpackChunkName: "components/icon-pencil" */).then(c => wrapFunctional(c.default || c))
export const IconPlay = () => import('../../components/Atom/Icon/IconPlay.vue' /* webpackChunkName: "components/icon-play" */).then(c => wrapFunctional(c.default || c))
export const IconPlayCircled = () => import('../../components/Atom/Icon/IconPlayCircled.vue' /* webpackChunkName: "components/icon-play-circled" */).then(c => wrapFunctional(c.default || c))
export const IconPowerSwitch = () => import('../../components/Atom/Icon/IconPowerSwitch.vue' /* webpackChunkName: "components/icon-power-switch" */).then(c => wrapFunctional(c.default || c))
export const IconSpotify = () => import('../../components/Atom/Icon/IconSpotify.vue' /* webpackChunkName: "components/icon-spotify" */).then(c => wrapFunctional(c.default || c))
export const IconThreeLines = () => import('../../components/Atom/Icon/IconThreeLines.vue' /* webpackChunkName: "components/icon-three-lines" */).then(c => wrapFunctional(c.default || c))
export const IconTwitter = () => import('../../components/Atom/Icon/IconTwitter.vue' /* webpackChunkName: "components/icon-twitter" */).then(c => wrapFunctional(c.default || c))
export const IconTwitterOutline = () => import('../../components/Atom/Icon/IconTwitterOutline.vue' /* webpackChunkName: "components/icon-twitter-outline" */).then(c => wrapFunctional(c.default || c))
export const IconYoutube = () => import('../../components/Atom/Icon/IconYoutube.vue' /* webpackChunkName: "components/icon-youtube" */).then(c => wrapFunctional(c.default || c))
export const IconYoutubeOutline = () => import('../../components/Atom/Icon/IconYoutubeOutline.vue' /* webpackChunkName: "components/icon-youtube-outline" */).then(c => wrapFunctional(c.default || c))
export const TypographyHeading = () => import('../../components/Atom/Typography/TypographyHeading.vue' /* webpackChunkName: "components/typography-heading" */).then(c => wrapFunctional(c.default || c))
export const TypographyIntroduction = () => import('../../components/Atom/Typography/TypographyIntroduction.vue' /* webpackChunkName: "components/typography-introduction" */).then(c => wrapFunctional(c.default || c))
export const TypographyParagraph = () => import('../../components/Atom/Typography/TypographyParagraph.vue' /* webpackChunkName: "components/typography-paragraph" */).then(c => wrapFunctional(c.default || c))
export const DebugDump = () => import('../../components/Global/DebugDump.vue' /* webpackChunkName: "components/debug-dump" */).then(c => wrapFunctional(c.default || c))
export const SmoothReflow = () => import('../../components/Global/SmoothReflow.vue' /* webpackChunkName: "components/smooth-reflow" */).then(c => wrapFunctional(c.default || c))
export const FormieFieldAgree = () => import('../../components/Global/Formie/FormieFieldAgree.vue' /* webpackChunkName: "components/formie-field-agree" */).then(c => wrapFunctional(c.default || c))
export const FormieFieldCheckboxes = () => import('../../components/Global/Formie/FormieFieldCheckboxes.vue' /* webpackChunkName: "components/formie-field-checkboxes" */).then(c => wrapFunctional(c.default || c))
export const FormieFieldDate = () => import('../../components/Global/Formie/FormieFieldDate.vue' /* webpackChunkName: "components/formie-field-date" */).then(c => wrapFunctional(c.default || c))
export const FormieFieldDropdown = () => import('../../components/Global/Formie/FormieFieldDropdown.vue' /* webpackChunkName: "components/formie-field-dropdown" */).then(c => wrapFunctional(c.default || c))
export const FormieFieldEmail = () => import('../../components/Global/Formie/FormieFieldEmail.vue' /* webpackChunkName: "components/formie-field-email" */).then(c => wrapFunctional(c.default || c))
export const FormieFieldEntries = () => import('../../components/Global/Formie/FormieFieldEntries.vue' /* webpackChunkName: "components/formie-field-entries" */).then(c => wrapFunctional(c.default || c))
export const FormieFieldFileUpload = () => import('../../components/Global/Formie/FormieFieldFileUpload.vue' /* webpackChunkName: "components/formie-field-file-upload" */).then(c => wrapFunctional(c.default || c))
export const FormieFieldGroup = () => import('../../components/Global/Formie/FormieFieldGroup.vue' /* webpackChunkName: "components/formie-field-group" */).then(c => wrapFunctional(c.default || c))
export const FormieFieldHeading = () => import('../../components/Global/Formie/FormieFieldHeading.vue' /* webpackChunkName: "components/formie-field-heading" */).then(c => wrapFunctional(c.default || c))
export const FormieFieldHidden = () => import('../../components/Global/Formie/FormieFieldHidden.vue' /* webpackChunkName: "components/formie-field-hidden" */).then(c => wrapFunctional(c.default || c))
export const FormieFieldHtml = () => import('../../components/Global/Formie/FormieFieldHtml.vue' /* webpackChunkName: "components/formie-field-html" */).then(c => wrapFunctional(c.default || c))
export const FormieFieldMultiLineText = () => import('../../components/Global/Formie/FormieFieldMultiLineText.vue' /* webpackChunkName: "components/formie-field-multi-line-text" */).then(c => wrapFunctional(c.default || c))
export const FormieFieldName = () => import('../../components/Global/Formie/FormieFieldName.vue' /* webpackChunkName: "components/formie-field-name" */).then(c => wrapFunctional(c.default || c))
export const FormieFieldNumber = () => import('../../components/Global/Formie/FormieFieldNumber.vue' /* webpackChunkName: "components/formie-field-number" */).then(c => wrapFunctional(c.default || c))
export const FormieFieldPhone = () => import('../../components/Global/Formie/FormieFieldPhone.vue' /* webpackChunkName: "components/formie-field-phone" */).then(c => wrapFunctional(c.default || c))
export const FormieFieldRadio = () => import('../../components/Global/Formie/FormieFieldRadio.vue' /* webpackChunkName: "components/formie-field-radio" */).then(c => wrapFunctional(c.default || c))
export const FormieFieldRepeater = () => import('../../components/Global/Formie/FormieFieldRepeater.vue' /* webpackChunkName: "components/formie-field-repeater" */).then(c => wrapFunctional(c.default || c))
export const FormieFieldSection = () => import('../../components/Global/Formie/FormieFieldSection.vue' /* webpackChunkName: "components/formie-field-section" */).then(c => wrapFunctional(c.default || c))
export const FormieFieldSingleLineText = () => import('../../components/Global/Formie/FormieFieldSingleLineText.vue' /* webpackChunkName: "components/formie-field-single-line-text" */).then(c => wrapFunctional(c.default || c))
export const FormieForm = () => import('../../components/Global/Formie/FormieForm.vue' /* webpackChunkName: "components/formie-form" */).then(c => wrapFunctional(c.default || c))
export const FormieRows = () => import('../../components/Global/Formie/FormieRows.vue' /* webpackChunkName: "components/formie-rows" */).then(c => wrapFunctional(c.default || c))
export const ControlPanel = () => import('../../components/Molecule/ControlPanel.vue' /* webpackChunkName: "components/control-panel" */).then(c => wrapFunctional(c.default || c))
export const FacetBlock = () => import('../../components/Molecule/FacetBlock.vue' /* webpackChunkName: "components/facet-block" */).then(c => wrapFunctional(c.default || c))
export const Picture = () => import('../../components/Molecule/Picture.vue' /* webpackChunkName: "components/picture" */).then(c => wrapFunctional(c.default || c))
export const SharePageSocials = () => import('../../components/Molecule/SharePageSocials.vue' /* webpackChunkName: "components/share-page-socials" */).then(c => wrapFunctional(c.default || c))
export const Skiplink = () => import('../../components/Molecule/Skiplink.vue' /* webpackChunkName: "components/skiplink" */).then(c => wrapFunctional(c.default || c))
export const Video = () => import('../../components/Molecule/Video.vue' /* webpackChunkName: "components/video" */).then(c => wrapFunctional(c.default || c))
export const ButtonIcon = () => import('../../components/Molecule/Button/ButtonIcon.vue' /* webpackChunkName: "components/button-icon" */).then(c => wrapFunctional(c.default || c))
export const ButtonLink = () => import('../../components/Molecule/Button/ButtonLink.vue' /* webpackChunkName: "components/button-link" */).then(c => wrapFunctional(c.default || c))
export const ButtonMedia = () => import('../../components/Molecule/Button/ButtonMedia.vue' /* webpackChunkName: "components/button-media" */).then(c => wrapFunctional(c.default || c))
export const ButtonText = () => import('../../components/Molecule/Button/ButtonText.vue' /* webpackChunkName: "components/button-text" */).then(c => wrapFunctional(c.default || c))
export const CardImageText = () => import('../../components/Molecule/Card/ImageText.vue' /* webpackChunkName: "components/card-image-text" */).then(c => wrapFunctional(c.default || c))
export const CardInfoPanel = () => import('../../components/Molecule/Card/InfoPanel.vue' /* webpackChunkName: "components/card-info-panel" */).then(c => wrapFunctional(c.default || c))
export const CardMinimal = () => import('../../components/Molecule/Card/Minimal.vue' /* webpackChunkName: "components/card-minimal" */).then(c => wrapFunctional(c.default || c))
export const CardOverview = () => import('../../components/Molecule/Card/Overview.vue' /* webpackChunkName: "components/card-overview" */).then(c => wrapFunctional(c.default || c))
export const CardPerson = () => import('../../components/Molecule/Card/Person.vue' /* webpackChunkName: "components/card-person" */).then(c => wrapFunctional(c.default || c))
export const CardRelated = () => import('../../components/Molecule/Card/Related.vue' /* webpackChunkName: "components/card-related" */).then(c => wrapFunctional(c.default || c))
export const CardSpotlight = () => import('../../components/Molecule/Card/Spotlight.vue' /* webpackChunkName: "components/card-spotlight" */).then(c => wrapFunctional(c.default || c))
export const CardStory = () => import('../../components/Molecule/Card/Story.vue' /* webpackChunkName: "components/card-story" */).then(c => wrapFunctional(c.default || c))
export const StyleguideDefinitionList = () => import('../../components/Molecule/Styleguide/StyleguideDefinitionList.vue' /* webpackChunkName: "components/styleguide-definition-list" */).then(c => wrapFunctional(c.default || c))
export const StyleguideElement = () => import('../../components/Molecule/Styleguide/StyleguideElement.vue' /* webpackChunkName: "components/styleguide-element" */).then(c => wrapFunctional(c.default || c))
export const StyleguidePage = () => import('../../components/Molecule/Styleguide/StyleguidePage.vue' /* webpackChunkName: "components/styleguide-page" */).then(c => wrapFunctional(c.default || c))
export const Matrix = () => import('../../components/Molecule/Matrix/Matrix.vue' /* webpackChunkName: "components/matrix" */).then(c => wrapFunctional(c.default || c))
export const MatrixBanner = () => import('../../components/Molecule/Matrix/MatrixBanner.vue' /* webpackChunkName: "components/matrix-banner" */).then(c => wrapFunctional(c.default || c))
export const MatrixCookiesConsent = () => import('../../components/Molecule/Matrix/MatrixCookiesConsent.vue' /* webpackChunkName: "components/matrix-cookies-consent" */).then(c => wrapFunctional(c.default || c))
export const MatrixEmployeeSpotlight = () => import('../../components/Molecule/Matrix/MatrixEmployeeSpotlight.vue' /* webpackChunkName: "components/matrix-employee-spotlight" */).then(c => wrapFunctional(c.default || c))
export const MatrixForm = () => import('../../components/Molecule/Matrix/MatrixForm.vue' /* webpackChunkName: "components/matrix-form" */).then(c => wrapFunctional(c.default || c))
export const MatrixImageLarge = () => import('../../components/Molecule/Matrix/MatrixImageLarge.vue' /* webpackChunkName: "components/matrix-image-large" */).then(c => wrapFunctional(c.default || c))
export const MatrixLinkList = () => import('../../components/Molecule/Matrix/MatrixLinkList.vue' /* webpackChunkName: "components/matrix-link-list" */).then(c => wrapFunctional(c.default || c))
export const MatrixOverview = () => import('../../components/Molecule/Matrix/MatrixOverview.vue' /* webpackChunkName: "components/matrix-overview" */).then(c => wrapFunctional(c.default || c))
export const MatrixProjectSpotlight = () => import('../../components/Molecule/Matrix/MatrixProjectSpotlight.vue' /* webpackChunkName: "components/matrix-project-spotlight" */).then(c => wrapFunctional(c.default || c))
export const MatrixRelatedMinimal = () => import('../../components/Molecule/Matrix/MatrixRelatedMinimal.vue' /* webpackChunkName: "components/matrix-related-minimal" */).then(c => wrapFunctional(c.default || c))
export const MatrixStories = () => import('../../components/Molecule/Matrix/MatrixStories.vue' /* webpackChunkName: "components/matrix-stories" */).then(c => wrapFunctional(c.default || c))
export const MatrixTable = () => import('../../components/Molecule/Matrix/MatrixTable.vue' /* webpackChunkName: "components/matrix-table" */).then(c => wrapFunctional(c.default || c))
export const MatrixText = () => import('../../components/Molecule/Matrix/MatrixText.vue' /* webpackChunkName: "components/matrix-text" */).then(c => wrapFunctional(c.default || c))
export const MatrixVideo = () => import('../../components/Molecule/Matrix/MatrixVideo.vue' /* webpackChunkName: "components/matrix-video" */).then(c => wrapFunctional(c.default || c))
export const Breadcrumbs = () => import('../../components/Organism/Breadcrumbs.vue' /* webpackChunkName: "components/breadcrumbs" */).then(c => wrapFunctional(c.default || c))
export const Footer = () => import('../../components/Organism/Footer.vue' /* webpackChunkName: "components/footer" */).then(c => wrapFunctional(c.default || c))
export const InteractiveMap = () => import('../../components/Organism/InteractiveMap.vue' /* webpackChunkName: "components/interactive-map" */).then(c => wrapFunctional(c.default || c))
export const Navigation = () => import('../../components/Organism/Navigation.vue' /* webpackChunkName: "components/navigation" */).then(c => wrapFunctional(c.default || c))
export const PageHeader = () => import('../../components/Organism/PageHeader.vue' /* webpackChunkName: "components/page-header" */).then(c => wrapFunctional(c.default || c))
export const PageHeaderFullSize = () => import('../../components/Organism/PageHeaderFullSize.vue' /* webpackChunkName: "components/page-header-full-size" */).then(c => wrapFunctional(c.default || c))
export const Pagination = () => import('../../components/Organism/Pagination.vue' /* webpackChunkName: "components/pagination" */).then(c => wrapFunctional(c.default || c))
export const SiteHeader = () => import('../../components/Organism/SiteHeader.vue' /* webpackChunkName: "components/site-header" */).then(c => wrapFunctional(c.default || c))
export const CookieContentBlocked = () => import('../../components/Organism/Cookie/ContentBlocked.vue' /* webpackChunkName: "components/cookie-content-blocked" */).then(c => wrapFunctional(c.default || c))
export const CookieGuard = () => import('../../components/Organism/Cookie/CookieGuard.vue' /* webpackChunkName: "components/cookie-guard" */).then(c => wrapFunctional(c.default || c))
export const GenericSearchPagination = () => import('../../components/Organism/GenericSearch/GenericSearchPagination.vue' /* webpackChunkName: "components/generic-search-pagination" */).then(c => wrapFunctional(c.default || c))
export const GenericSearchResultSummary = () => import('../../components/Organism/GenericSearch/GenericSearchResultSummary.vue' /* webpackChunkName: "components/generic-search-result-summary" */).then(c => wrapFunctional(c.default || c))
export const GenericSearchResults = () => import('../../components/Organism/GenericSearch/GenericSearchResults.vue' /* webpackChunkName: "components/generic-search-results" */).then(c => wrapFunctional(c.default || c))
export const GenericSearchSearch = () => import('../../components/Organism/GenericSearch/GenericSearchSearch.vue' /* webpackChunkName: "components/generic-search-search" */).then(c => wrapFunctional(c.default || c))
export const OverviewFilterPanel = () => import('../../components/Organism/Overview/OverviewFilterPanel.vue' /* webpackChunkName: "components/overview-filter-panel" */).then(c => wrapFunctional(c.default || c))
export const OverviewFilterPanelWide = () => import('../../components/Organism/Overview/OverviewFilterPanelWide.vue' /* webpackChunkName: "components/overview-filter-panel-wide" */).then(c => wrapFunctional(c.default || c))
export const OverviewPagination = () => import('../../components/Organism/Overview/OverviewPagination.vue' /* webpackChunkName: "components/overview-pagination" */).then(c => wrapFunctional(c.default || c))
export const OverviewResultSummary = () => import('../../components/Organism/Overview/OverviewResultSummary.vue' /* webpackChunkName: "components/overview-result-summary" */).then(c => wrapFunctional(c.default || c))
export const OverviewResults = () => import('../../components/Organism/Overview/OverviewResults.vue' /* webpackChunkName: "components/overview-results" */).then(c => wrapFunctional(c.default || c))
export const OverviewResultsWide = () => import('../../components/Organism/Overview/OverviewResultsWide.vue' /* webpackChunkName: "components/overview-results-wide" */).then(c => wrapFunctional(c.default || c))
export const OverviewSearch = () => import('../../components/Organism/Overview/OverviewSearch.vue' /* webpackChunkName: "components/overview-search" */).then(c => wrapFunctional(c.default || c))
export const OverviewSearchWide = () => import('../../components/Organism/Overview/OverviewSearchWide.vue' /* webpackChunkName: "components/overview-search-wide" */).then(c => wrapFunctional(c.default || c))
export const PersonOverviewResults = () => import('../../components/Organism/PersonOverview/PersonOverviewResults.vue' /* webpackChunkName: "components/person-overview-results" */).then(c => wrapFunctional(c.default || c))
export const Base = () => import('../../components/Template/Base.vue' /* webpackChunkName: "components/base" */).then(c => wrapFunctional(c.default || c))
export const EventPage = () => import('../../components/Template/EventPage.vue' /* webpackChunkName: "components/event-page" */).then(c => wrapFunctional(c.default || c))
export const GenericPage = () => import('../../components/Template/GenericPage.vue' /* webpackChunkName: "components/generic-page" */).then(c => wrapFunctional(c.default || c))
export const GenericSearchPage = () => import('../../components/Template/GenericSearchPage.vue' /* webpackChunkName: "components/generic-search-page" */).then(c => wrapFunctional(c.default || c))
export const Home = () => import('../../components/Template/Home.vue' /* webpackChunkName: "components/home" */).then(c => wrapFunctional(c.default || c))
export const NewsPage = () => import('../../components/Template/NewsPage.vue' /* webpackChunkName: "components/news-page" */).then(c => wrapFunctional(c.default || c))
export const Overview = () => import('../../components/Template/Overview.vue' /* webpackChunkName: "components/overview" */).then(c => wrapFunctional(c.default || c))
export const OverviewPage = () => import('../../components/Template/OverviewPage.vue' /* webpackChunkName: "components/overview-page" */).then(c => wrapFunctional(c.default || c))
export const OverviewWide = () => import('../../components/Template/OverviewWide.vue' /* webpackChunkName: "components/overview-wide" */).then(c => wrapFunctional(c.default || c))
export const PersonPage = () => import('../../components/Template/PersonPage.vue' /* webpackChunkName: "components/person-page" */).then(c => wrapFunctional(c.default || c))
export const ProductPage = () => import('../../components/Template/ProductPage.vue' /* webpackChunkName: "components/product-page" */).then(c => wrapFunctional(c.default || c))
export const ProjectPage = () => import('../../components/Template/ProjectPage.vue' /* webpackChunkName: "components/project-page" */).then(c => wrapFunctional(c.default || c))
export const PublicationPage = () => import('../../components/Template/PublicationPage.vue' /* webpackChunkName: "components/publication-page" */).then(c => wrapFunctional(c.default || c))
export const StoryPage = () => import('../../components/Template/StoryPage.vue' /* webpackChunkName: "components/story-page" */).then(c => wrapFunctional(c.default || c))
export const UnknownPage = () => import('../../components/Template/UnknownPage.vue' /* webpackChunkName: "components/unknown-page" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
