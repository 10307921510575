
import { defineComponent } from '@nuxtjs/composition-api'
import { mapGetters, mapMutations } from 'vuex'
import { setupTranslationPrefixer } from '~/assets/translation'
import LogoDeltares from '~/components/Atom/Brand/LogoDeltares'
import { removeTrailingSlash } from '@/assets/link'

const OFFSET = 60

export default defineComponent({
  components: { LogoDeltares },
  props: {
    pageType: { type: String, required: true },
  },
  setup() {
    return {
      ...setupTranslationPrefixer('navigation'),
    }
  },
  data() {
    return {
      showMobileMenu: false,
      showDesktopMenu: false,
      showLanguageMenu: false,
      showSearchPanel: false,
      currentlyVisible: undefined,
      showServiceBar: true,
      lastScrollPosition: 0,
      scrollValue: 0,
    }
  },
  computed: {
    ...mapGetters({
      main: 'navigation/main',
      service: 'navigation/service',
      alternativeLanguages: 'page/alternativeLanguages',
    }),
  },
  mounted() {
    this.lastScrollPosition = window.pageYOffset
    window.addEventListener('scroll', this.onScroll)
    const viewportMeta = document.createElement('meta')
    viewportMeta.name = 'viewport'
    viewportMeta.content = 'width=device-width, initial-scale=1'
    document.head.appendChild(viewportMeta)

    // Prevent desktop menu from staying open when new page is loaded
    this.closeAll()
    document.querySelector('body').classList.remove('overflow-hidden')
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.onScroll)
  },
  methods: {
    removeTrailingSlash,
    ...mapMutations({
      closeAll: 'navigation/closeAll',
      toggleOpen: 'navigation/toggleOpen',
      toggleOverflowMobile: 'navigation/toggleOverflowMobile',
    }),
    toggleCurrentlyVisible(part) {
      const isMobileMenu = ['language', 'mobile'].includes(part)

      if (part === this.currentlyVisible) {
        this.currentlyVisible = undefined
        if (isMobileMenu) {
          this.showMobileMenu = false
        }
      } else {
        this.currentlyVisible = part
        if (isMobileMenu) {
          this.showMobileMenu = true
        }
      }
    },
    onScroll() {
      if (window.pageYOffset < 0) {
        return
      }
      if (Math.abs(window.pageYOffset - this.lastScrollPosition) < OFFSET) {
        return
      }
      this.showServiceBar = window.pageYOffset < this.lastScrollPosition
      this.lastScrollPosition = window.pageYOffset
    },
  },
})
