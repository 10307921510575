import { defineNuxtPlugin } from '@nuxtjs/composition-api'

class FetchError extends Error {
  constructor(message, options) {
    super(message)
    this.uri = options?.uri
    this.input = options?.input
    this.output = options?.output
  }
}

export default defineNuxtPlugin((context, inject) => {
  // REST endpoint
  inject('restFetch', async (uri) => {
    const urlParts = [context.$config.cmsSiteUrl, uri]
    const preview = context.query['x-craft-preview'] || context.query['x-craft-live-preview'] || undefined
    if (preview && context.query.token) {
      urlParts.push(`?preview=${preview}&token=${context.query.token}`)
    }
    const url = urlParts.join('')
    const response = await fetch(url)
    if (response.status !== 200) {
      throw new FetchError(`Unable to fetch ${url}`, { uri, input: { query: context.query }, output: { status: response.status } })
    }

    return await response.json()
  })

  // GraphQL endpoint
  inject('graphqlFetch', async ({ query, variables, token }) => {
    const tokenMap = {
      elasticsearch: context.$config.elasticsearchGraphqlPublicToken,
      formie: context.$config.formieGraphqlPublicToken,
    }

    let response
    let payload
    try {
      response = await fetch(`${context.$config.cmsSiteUrl}/api`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          ...(tokenMap[token] ? { Authorization: `Bearer ${tokenMap[token]}` } : {}),
        },
        body: JSON.stringify({ query, variables }),
      })

      if (response.status !== 200) {
        throw new FetchError('GraphQL error', { input: { query, variables }, output: { status: response.status } })
      }

      payload = await response.json()
      if (payload?.errors?.length) {
        throw new FetchError('GraphQL error', { input: { query, variables }, output: payload?.errors })
      }

      return payload?.data
    } finally {
      if (process.dev) {
        /* eslint-disable no-console */
        if (process.client || response?.status !== 200 || payload?.errors?.length) {
          console.groupCollapsed(`graphqlFetch - ${token}`)
          console.info(`token: ${token}`)
          console.info(query)
          console.info(variables)
          console.info(response)
          console.info(payload)
          console.groupEnd()
        } else {
          console.info(`graphqlFetch - ${token}`)
        }
        /* eslint-enable no-console */
      }
    }
  })
})
