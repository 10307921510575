export const state = () => ({
  // The current path, used to determine what the active navigation element is
  fullPath: undefined,

  // The id of the current navigation item that is toggled open
  openItemId: undefined,

  // Navigation elements
  navigation: { footer: [], main: [], service: [] },
})

const annotateNavigationItems = (items, openItemId, fullPath) => {
  return (
    items?.map((item) => {
      const children = annotateNavigationItems(item.children, openItemId, fullPath)
      return {
        ...item,
        isOpen: children.length && (children.some((child) => child.isOpen) || item.id === openItemId),
        isActive:
          children.some((child) => child.isActive) ||
          (item.url && item.url?.match(/^(https?:\/\/[^/]+)?(?<fullPath>.*)/i)?.groups.fullPath === fullPath) ||
          fullPath.includes(item.url && item.url?.match(/^(https?:\/\/[^/]+)?(?<fullPath>.*)/i)?.groups.fullPath + '/'),
        children,
      }
    }) || []
  )
}

export const getters = {
  footer: (state) => annotateNavigationItems(state.navigation.footer, state.openItemId, state.fullPath),
  footerService: (state) => annotateNavigationItems(state.navigation.footerservice, state.openItemId, state.fullPath),
  main: (state) => annotateNavigationItems(state.navigation.main, state.openItemId, state.fullPath),
  service: (state) => annotateNavigationItems(state.navigation.service, state.openItemId, state.fullPath),
}

export const mutations = {
  closeAll: (state) => {
    state.openItemId = undefined
    const isDesktop = window.innerWidth > 1024
    if (isDesktop) {
      document.querySelector('body').classList.remove('overflow-hidden')
    }
  },
  setNavigation: (state, navigation) => (state.navigation = navigation),
  setRoute: (state, route) => (state.fullPath = route.fullPath),
  toggleOpen: (state, id) => {
    const isMenuClosing = state.openItemId === id
    state.openItemId = isMenuClosing ? undefined : id
    // specify desktop screen width explicitly, because mobile menu is structured differently
    const isDesktop = window.innerWidth > 1024
    if (isDesktop) {
      if (isMenuClosing) {
        document.querySelector('body').classList.remove('overflow-hidden')
      } else {
        document.querySelector('body').classList.add('overflow-hidden')
      }
    }
  },
  toggleOverflowMobile: (state, addOverflow) => {
    if (addOverflow) {
      document.querySelector('body').classList.add('overflow-hidden')
    } else {
      document.querySelector('body').classList.remove('overflow-hidden')
    }
  },
}

export const actions = {
  async fetch({ commit, dispatch }) {
    const key = `navigation;${this.$i18n.locale}`
    const ttl = 3600

    const fetcher = async () => {
      const [footer, footerservice, main, service] = await Promise.all([
        (await this.$restFetch(`/api/${this.$i18n.locale}/navigation/footer.json`))?.data,
        (await this.$restFetch(`/api/${this.$i18n.locale}/navigation/footer-service.json`))?.data,
        (await this.$restFetch(`/api/${this.$i18n.locale}/navigation/main.json`))?.data,
        (await this.$restFetch(`/api/${this.$i18n.locale}/navigation/service.json`))?.data,
      ])
      return { footer, footerservice, main, service }
    }

    const navigation = await dispatch('cache/fetch', { key, ttl, fetcher }, { root: true })
    commit('setNavigation', navigation)
    return navigation
  },
}
