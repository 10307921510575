
import { defineComponent } from '@nuxtjs/composition-api'

export default defineComponent({
  props: {
    text: { type: [String, Object], required: true },
    textColor: { type: [String, Object], required: false, default: 'text-theme-secondary' },
    margins: { type: Array, required: false, default: undefined },
    lang: { type: String, required: false, default: undefined },
  },
})
