
import { defineComponent } from '@nuxtjs/composition-api'

export default defineComponent({
  props: {
    url: { type: [String, Object], required: false, default: undefined },
    target: { type: String, required: false, default: undefined },
    size: { type: [String, Object], required: false, default: 'medium' },
    hasBorder: { type: Boolean, required: false, default: true },
    iconColor: { type: [String, Object], required: false, default: 'text-theme-quaternary hover:text-theme-tertiary focus:text-theme-tertiary' },
    backgroundColor: { type: [String, Object], required: false, default: 'bg-transparent hover:bg-theme-secondary focus:bg-theme-secondary' },
  },
})
