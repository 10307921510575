/**
 * Order these breakpoints from large to small
 *
 * @type {[{minWidth: number, tag: string}, {minWidth: number, tag: string}, {minWidth: number, tag: string}, {minWidth: number, tag: string}, {minWidth: number, tag: string}, {minWidth: number, tag: string}]}
 */
const mediaBreakpoints = [
  { tag: '2xl', minWidth: 1536 },
  { tag: 'xl', minWidth: 1280 },
  { tag: 'lg', minWidth: 1024 },
  { tag: 'md', minWidth: 768 },
  { tag: 'sm', minWidth: 640 },
  { tag: 'xs', minWidth: 0 },
]

/**
 * Returns an object used by the MoleculePicture component
 *
 * The `createImagesMediaQuery(...)` function helps to retrieve data from
 * CraftCMS, through the GraphQL API, and returns image data in the format
 * required by this `getMediaImage(...)` function.
 *
 * @returns {{img: {src: string, width: number, media: string, height: number}, sources: {src: string, width: number, media: string, height: number}[]}}
 */
export function getMediaImage(image) {
  if (!image) {
    return { img: undefined, sources: [] }
  }
  const sources = mediaBreakpoints
    .filter(({ tag }) => image[`_${tag}Url`])
    .map(({ tag, minWidth }) => ({
      media: `(min-width: ${minWidth}px)`,
      src: image[`_${tag}Url`],
      width: image[`_${tag}Width`],
      height: image[`_${tag}Height`],
    }))
  return {
    ...image,
    img: sources[sources.length - 1],
    sources: sources.slice(0, sources.length - 1),
  }
}

/** Returns the url, width, and height of the largest image */
export function getMediaSeoImage(image) {
  if (image) {
    // Return the url of the largest breakpoint available
    for (const { tag } of mediaBreakpoints) {
      if (image[`_${tag}Url`]) {
        return {
          url: image[`_${tag}Url`],
          width: image[`_${tag}Width`],
          height: image[`_${tag}Height`],
        }
      }
    }
  }

  return { url: undefined, width: undefined, height: undefined }
}
