import xor from 'lodash/xor'
import { sortAuthorFacetsByDutchSurname } from '@/assets/sort'

/**
 * Mapping between the overview page that we are viewing and the pages we want to search for
 */
const sectionMapping = {
  eventOverview: 'eventPage',
  newsOverview: 'newsPage',
  personOverview: 'personPage',
  productOverview: 'productPage',
  projectOverview: 'projectPage',
  publicationOverview: 'publicationPage',
  storyOverview: 'storyPage',
}

const orderByMapping = {
  eventPage: 'eventStartDate ASC',
  newsPage: 'postDate DESC',
  personPage: 'title ASC',
  productPage: 'title ASC',
  projectPage: 'postDate DESC',
  publicationPage: 'postDate DESC',
  storyPage: 'postDate DESC',
}

/**
 * List with the facets where the sections determine if they are enabled
 *
 * Ordered how it should be shown on the overview page
 */
const facetsMapping = [
  {
    name: 'themes',
    graphql: 'themeTags { label: value, count }',
    sections: ['eventPage', 'newsPage', 'personPage', 'productPage', 'projectPage', 'publicationPage'],
  },
  { name: 'types', graphql: 'productTypeTags { label: value, count }', sections: ['productPage'] },
  {
    name: 'expertises',
    graphql: 'expertiseTags { label: value, count }',
    sections: ['eventPage', 'newsPage', 'personPage', 'productPage', 'projectPage', 'publicationPage', 'storyPage'],
  },
  { name: 'storyTypes', graphql: 'storyTypeTags { label: value, count }', sections: ['storyPage'] },
  { name: 'languages', graphql: 'publicationLanguage { label: value, count }', sections: ['publicationPage'] },
  {
    name: 'materials',
    graphql: 'materialType { label: value, count }',
    sections: ['publicationPage'],
  },
  { name: 'countries', graphql: 'projectCountryTag { label: value, count }', sections: ['projectPage'] },
  { name: 'authors', graphql: 'publicationAuthor { label: value, count }', sections: ['publicationPage'] },
]

export const STATUS = {
  INITIALIZING: 'INITIALIZING',
  FETCHING: 'FETCHING',
  READY: 'READY',
  ERROR: 'ERROR',
}

export const state = () => {
  return {
    // We will use this store for different sections, i.e. eventPage, productPage, etc.
    // Based on the section the graphql queries will be slightly different and other search criteria will be available.
    section: undefined,

    // Status of the store. One of the STATUS.<status> values or undefined
    status: undefined,

    // Global results (i.e. unaffected by search context)
    allFacets: {},

    // Search context
    countries: [],
    expertises: [],
    keyword: '',
    languages: [],
    materials: [],
    page: 1,
    storyTypes: [],
    themes: [],
    types: [],
    authors: [],

    // Search results
    facets: {},
    hits: [],
    results: [],
    totalHits: 0,
  }
}

export const getters = {
  // Constants
  hitsPerPage: () => 12,

  // State that is based on the overview template that we are viewing
  section: (state) => state.section,

  status: (state) => state.status,

  // Search context
  countries: (state) => state.countries,
  expertises: (state) => state.expertises,
  keyword: (state) => state.keyword,
  languages: (state) => state.languages,
  materials: (state) => state.materials,
  page: (state) => state.page,
  storyTypes: (state) => state.storyTypes,
  themes: (state) => state.themes,
  types: (state) => state.types,
  authors: (state) => state.authors,

  // Url parameters
  urlParameters: (state) => ({
    countries: state.countries,
    expertises: state.expertises,
    keyword: state.keyword || undefined,
    languages: state.languages,
    materials: state.materials,
    page: state.page === 1 ? undefined : state.page,
    storyTypes: state.storyTypes,
    themes: state.themes,
    types: state.types,
    authors: state.authors,
  }),

  // Search results
  facets: (state) => {
    const { allFacets, facets, section } = state
    const helper = (fromAllFacets, fromFacets, activeList) => {
      const currentCountMap = Object.fromEntries((fromFacets || []).map(({ count, label, value }) => [value || label, count]))
      return (fromAllFacets || []).map(({ value, label }) => ({
        active: activeList.includes(value || label),
        count: currentCountMap[value || label] || 0,
        label,
        value: value || label,
      }))
    }
    return facetsMapping
      .filter(({ sections }) => sections.includes(section))
      .map(({ name }) => ({ name, values: helper(allFacets[name], facets[name], state[name]) }))
      .filter(({ values }) => values.length > 0)
  },
  activeFacets: (state) => {
    const { allFacets, section } = state
    const helper = (fromAllFacets, activeList, name) =>
      (fromAllFacets || []).filter(({ value, label }) => activeList.includes(value || label)).map(({ value, label }) => ({ name, label, value: value || label }))
    return facetsMapping.filter(({ sections }) => sections.includes(section)).flatMap(({ name }) => helper(allFacets[name], state[name], name))
  },
  hits: (state) => state.hits,
  results: (state) => state.results,
  totalHits: (state) => state.totalHits,
}

export const mutations = {
  // Global results (i.e. unaffected by search context)
  setAllFacets: (state, allFacets) => {
    if (allFacets.authors) {
      allFacets.authors = sortAuthorFacetsByDutchSurname(allFacets.authors)
    }
    state.allFacets = allFacets
  },

  // Initialize
  initialize: (state, { countries, expertises, keyword, languages, materials, authors, page, section, storyTypes, themes, types }) => {
    state.status = STATUS.INITIALIZING

    const toArray = (value) => (value === undefined ? [] : Array.isArray(value) ? value : [value])
    state.countries = toArray(countries)
    state.expertises = toArray(expertises)
    state.keyword = typeof keyword === 'string' ? keyword : ''
    state.languages = toArray(languages)
    state.materials = toArray(materials)
    state.page = typeof page === 'string' ? Math.max(1, Number.parseInt(page)) : 1
    state.storyTypes = toArray(storyTypes)
    state.themes = toArray(themes)
    state.types = toArray(types)
    state.authors = toArray(authors)

    // State that is based on the overview template that we are viewing
    state.section = sectionMapping[section]
  },

  // Search context
  reset(state) {
    state.countries = []
    state.expertises = []
    state.keyword = ''
    state.languages = []
    state.materials = []
    state.page = 1
    state.storyTypes = []
    state.themes = []
    state.types = []
    state.authors = []
    this.dispatch('overview/fetch')
  },

  setStatus(state, status) {
    if (status !== undefined && !Object.keys(STATUS).includes(status)) {
      throw new Error(`Invalid status: ${status}`)
    }
    state.status = status
  },

  setKeyword(state, keyword) {
    state.keyword = keyword
    state.page = 1
    this.dispatch('overview/fetch')
  },
  setPage(state, page) {
    state.page = Math.max(1, page)
    this.dispatch('overview/fetch')
  },
  toggleFacet(state, { name, value }) {
    for (const { name: mappingName, sections } of facetsMapping) {
      if (name === mappingName && sections.includes(state.section)) {
        state[name] = xor(state[name], [value])
        state.page = 1
        this.dispatch('overview/fetch')
        break
      }
    }
  },

  // Search results
  setFacets: (state, facets) => (state.facets = facets),
  setHits: (state, hits) => (state.hits = hits),
  setResults: (state, results) => (state.results = results),
  setTotalHits: (state, totalHits) => (state.totalHits = totalHits),
}

export const actions = {
  async fetchAllFacets({ commit, dispatch, getters }) {
    const { section } = getters
    const key = `overview;${section};${this.$i18n.locale};all-facets`
    const level = section === 'productPage' ? '1' : undefined
    const ttl = 900

    const fetcher = async () => {
      const { elasticSearch } = await this.$graphqlFetch({
        token: 'elasticsearch',
        query: `query facets($siteHandle:[ElasticSiteHandle], $sectionHandle:[ElasticSectionHandle], $level:[String]) {
          elasticSearch(siteHandle:$siteHandle, sectionHandle:$sectionHandle, level:$level) {
            facets(limit:1024) {
              ${facetsMapping
                .filter(({ sections }) => sections.includes(section))
                .map(({ name, graphql }) => `${name}: ${graphql}`)
                .join('\n')}
            }
          }
        }`,
        variables: { sectionHandle: section, siteHandle: `${this.$i18n.locale}Default`, level },
      })
      return elasticSearch.facets
    }

    // Fetch the hits
    const allFacets = await dispatch('cache/fetch', { key, ttl, fetcher, fallback: {} }, { root: true })

    commit('setAllFacets', allFacets)
    return allFacets
  },

  async fetch({ commit, dispatch, getters }) {
    const { section, status, countries, expertises, hitsPerPage, keyword, languages, materials, authors, page, storyTypes, themes, types } = getters
    if (status !== STATUS.INITIALIZING) {
      // Fetching while initializing is part of the initialization process, so don't change the status.
      commit('setStatus', STATUS.FETCHING)
    }
    const level = section === 'productPage' ? '1' : undefined
    const orderBy = orderByMapping[section]
    const key = [
      'overview',
      section,
      this.$i18n.locale,
      `keyword:${keyword}`,
      `countries:${countries.join(',')}`,
      `expertises:${expertises.join(',')}`,
      `languages:${languages.join(',')}`,
      `materials:${materials.join(',')}`,
      `orderBy:${orderBy}`,
      `storyTypes:${storyTypes.join(',')}`,
      `themes:${themes.join(',')}`,
      `types:${types.join(',')}`,
      `authors:${authors.join(',')}`,
    ].join(';')
    const ttl = 300

    const fetcher = async () => {
      // Fetch 1024 results, therefore we will not need to fetch new results when we switch pages
      const { elasticSearch } = await this.$graphqlFetch({
        token: 'elasticsearch',
        query: `query search($siteHandle:[ElasticSiteHandle], $sectionHandle:[ElasticSectionHandle], $query:String, $level:[String], $countries:[String], $expertises:[String], $languages:[String], $materials:[String], $authors:[String], $storyTypes:[String], $themes:[String], $types:[String], $orderBy:[String]) {
          elasticSearch(siteHandle:$siteHandle, sectionHandle:$sectionHandle, query:$query, level:$level, projectCountryTag:$countries, expertiseTags:$expertises, publicationLanguage:$languages, materialType:$materials, publicationAuthor:$authors, storyTypeTags:$storyTypes, themeTags:$themes, productTypeTags:$types, orderBy:$orderBy) {
            facets(limit:1024) {
            ${facetsMapping
              .filter(({ sections }) => sections.includes(section))
              .map(({ name, graphql }) => `${name}: ${graphql}`)
              .join('\n')}
            }
            count
            hits(limit:1024) {
             uri
            }
          }
        }`,
        variables: {
          countries,
          expertises,
          query: keyword,
          languages,
          level,
          materials,
          orderBy: keyword ? ['score DESC', orderBy] : orderBy,
          sectionHandle: section,
          siteHandle: `${this.$i18n.locale}Default`,
          storyTypes,
          themes,
          types,
          authors,
        },
      })

      return { facets: elasticSearch.facets, hits: elasticSearch.hits.map(({ uri }) => uri), totalHits: elasticSearch.count }
    }

    // Fetch the hits
    const { facets, hits, totalHits } = await dispatch('cache/fetch', { key, ttl, fetcher, fallback: { facets: {}, hits: [], totalHits: 0 } }, { root: true })
    commit('setFacets', facets)
    commit('setHits', hits)
    commit('setTotalHits', totalHits)

    // Initialize the series list with N empty elements, used to trigger loaders
    const slice = hits.slice((page - 1) * hitsPerPage, page * hitsPerPage)

    // Fetch all associated entries within the current page
    const results = await Promise.all(slice.map((uri) => dispatch('page/fetchUri', { uri }, { root: true })))
    commit(
      'setResults',
      results.filter((result) => result)
    )
    commit('setStatus', STATUS.READY)
    return hits
  },
}
