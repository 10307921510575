import { useContext } from '@nuxtjs/composition-api'

function getSiteUrlHelper(req, nuxtState, id, fallbackEnvVariableName) {
  // This strategy makes use the nuxt-env dependency
  const env = (req || nuxtState)?.env || {}
  return env[fallbackEnvVariableName]
}

export function useSiteConfig() {
  const { req, nuxtState } = useContext()
  return {
    bridgeUrl: getSiteUrlHelper(req, nuxtState, 'bridge', 'BRIDGE_SITE_URL') || 'bridge',
    cmsUrl: getSiteUrlHelper(req, nuxtState, 'cms', 'CMS_SITE_URL') || 'cms',
    wwwUrl: getSiteUrlHelper(req, nuxtState, 'www', 'WWW_SITE_URL') || 'www',
  }
}

export function getLinkRewrites($config) {
  return [
    [
      // The first host is the target for the rewrites in the list, it is environment dependent
      $config.wwwSiteUrl.match(/^(?<protocol>[^:]+:)?(\/\/(?<host>[^/]+))?(?<rest>.*$)/i).groups.host,
      'deltares.nl',
      'www.deltares.nl',
      't.deltares.nl',
      'a.deltares.nl',
      'p.deltares.nl',
      'localhost',
    ],
  ]
}

export function useLinkRewrites() {
  const { req, nuxtState } = useContext()
  const wwwUrl = getSiteUrlHelper(req, nuxtState, 'www', 'WWW_SITE_URL') || 'www'
  return {
    rewrites: [
      [
        // The first host is the target for the rewrites in the list, it is environment dependent
        wwwUrl.match(/^(?<protocol>[^:]+:)?(\/\/(?<host>[^/]+))?(?<rest>.*$)/i).groups.host,
        'deltares.nl',
        'www.deltares.nl',
        't.deltares.nl',
        'a.deltares.nl',
        'p.deltares.nl',
        'localhost',
      ],
    ],
  }
}
