
import { watch, defineComponent, ref, useContext } from '@nuxtjs/composition-api'
import { linkDetails } from '~/assets/link'
import { useLinkRewrites } from '~/assets/site'

const validTargets = ['_self', '_blank', '_parent', '_top']

export default defineComponent({
  props: {
    url: { type: [Object, String], required: false, default: undefined },
    target: { type: String, required: false, default: undefined, validator: (value) => validTargets.includes(value) },
    fallbackComponent: { type: String, required: false, default: 'span' },
    forceAnchor: { type: Boolean, required: false, default: false },
  },
  setup(props) {
    const { localePath, req } = useContext()
    const { rewrites } = useLinkRewrites()
    const currentHost = `//${req?.headers.host || window?.location.host}`
    const component = ref('span')
    const fallbackTarget = ref(undefined)
    const href = ref(undefined)
    const rel = ref(undefined)
    const to = ref(undefined)

    const setter = (values) => {
      component.value = values.component
      fallbackTarget.value = values.fallbackTarget
      href.value = values.href
      rel.value = values.rel
      to.value = values.to
    }

    const update = (targetUrl) => {
      if (targetUrl && typeof targetUrl === 'object') {
        targetUrl = localePath(targetUrl)
      }

      if (!targetUrl) {
        return setter({ component: props.fallbackComponent })
      }

      if (props.forceAnchor) {
        return setter({ component: 'a', href: targetUrl })
      }

      const { distance, scope, url } = linkDetails(currentHost, targetUrl, { rewrites })
      switch (scope) {
        // Use NuxtLink because this is inside the app
        case 'inside':
          return setter({ component: 'NuxtLink', href: url, to: url })

        // Fallback to old-school <a href=...>
        case 'outside':
        default:
          return setter(
            distance === -1
              ? // Going to a completely different domain, i.e. from zicht.nl to example.com
                { component: 'a', fallbackTarget: '_blank', href: url, rel: 'noopener' }
              : // Going to a subdomain of the same site, i.e. from zicht.nl to jobs.zicht.nl
                { component: 'a', href: url }
          )
      }
    }

    // Immediately set the link
    update(props.url)

    // Update the link whenever the url changes
    watch(props, () => update(props.url))

    return { component, fallbackTarget, href, rel, to }
  },
})
