const languageFallback = 'nl'
const languageMap = { 'nl-AW': 'nl', 'nl-BE': 'nl', 'nl-BQ': 'nl', 'nl-CQ': 'nl', 'nl-NL': 'nl', 'nl-SR': 'nl', 'nl-SX': 'nl' }

export const state = () => ({
  cacheKey: undefined,
})

export const getters = {
  page(state, getters, rootState, rootGetters) {
    return rootGetters['cache/get'](state.cacheKey)
  },
  alternativeLanguages(state, getters) {
    const { page } = getters
    const activeLanguage = languageMap[page?.language] || page?.language || languageFallback
    return (page?.alternativeLanguages || [])
      .map(({ language, url }) => ({ language: languageMap[language] || language, url }))
      .map((alternativeLanguage) => ({ ...alternativeLanguage, isActive: activeLanguage === alternativeLanguage.language }))
      .sort(({ language: a }, { language: b }) => (a < b ? 1 : -1))
  },
}

export const mutations = {
  cacheKey(state, key) {
    state.cacheKey = key
  },
}

export const actions = {
  async fetchUri({ commit, dispatch }, { uri, push }) {
    const key = `page;locale:${this.$i18n.locale};uri:${uri}`
    const ttl = 300

    const fetcher = async () => {
      return await this.$restFetch(`/api/${this.$i18n.locale}/uri/${encodeURI(uri)}.json`)
    }

    try {
      return await dispatch('cache/fetch', { key, ttl, fetcher }, { root: true })
    } finally {
      if (push) {
        commit('cacheKey', key)
      }
    }
  },
  // todo: error api endpoint is not yet available
  async fetchError({ commit, dispatch }, { statusCode, push }) {
    const key = `page;locale:${this.$i18n.locale};statusCode:${statusCode}`
    const ttl = 3600

    const fetcher = async () => {
      try {
        // Find an error page for this status code
        return await this.$restFetch(`/api/${this.$i18n.locale}/error/${statusCode}.json`)
      } catch {
        try {
          // Fallback to a generic error page
          return await this.$restFetch(`/api/${this.$i18n.locale}/error/fallback.json`)
        } catch {
          return { title: 'Er is helaas iets misgegaan' }
        }
      }
    }

    try {
      return await dispatch('cache/fetch', { key, ttl, fetcher }, { root: true })
    } finally {
      if (push) {
        commit('cacheKey', key)
      }
    }
  },
  async fetchHome({ commit, dispatch }, { push }) {
    const key = `page;locale:${this.$i18n.locale};home`
    const ttl = 300

    const fetcher = async () => {
      return await this.$restFetch(`/api/${this.$i18n.locale}/homepage.json`)
    }

    try {
      return await dispatch('cache/fetch', { key, ttl, fetcher }, { root: true })
    } finally {
      if (push) {
        commit('cacheKey', key)
      }
    }
  },
}
