import { render, staticRenderFns } from "./Picture.vue?vue&type=template&id=742aa495&scoped=true"
import script from "./Picture.vue?vue&type=script&lang=js"
export * from "./Picture.vue?vue&type=script&lang=js"
import style0 from "./Picture.vue?vue&type=style&index=0&id=742aa495&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "742aa495",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Picture: require('/home/userapp/components/Molecule/Picture.vue').default})
