export const actions = {
  async fetch({ commit, dispatch }, { path }) {
    const key = `redirects;locale:${this.$i18n.locale};uri:${path}`
    const ttl = 3600

    const fetcher = async () => {
      return await this.$restFetch(`/api/seo-helper/${this.$i18n.locale}Default/find-redirects.json?path=${encodeURI(path)}`)
    }

    return await dispatch('cache/fetch', { key, ttl, fetcher }, { root: true })
  },
}
