/**
 * @typedef {Object} AuthorFacet
 * @property {string} label
 * @property {number} count
 */

/**
 * @param {AuthorFacet[]} authorFacets
 */
export function sortAuthorFacetsByDutchSurname(authorFacets) {
  const prefixes = ['van', 'de', 'der', 'den', 'het', 'ten', 'ter', 'el']

  return authorFacets.sort((a, b) => {
    const aParts = a.label?.split(' ')
    const bParts = b.label?.split(' ')

    const aSurname = aParts.filter((word) => !prefixes.includes(word.toLowerCase())).pop()
    const bSurname = bParts.filter((word) => !prefixes.includes(word.toLowerCase())).pop()

    return aSurname.localeCompare(bSurname)
  })
}
