
import { defineComponent } from '@nuxtjs/composition-api'

export default defineComponent({
  props: {
    url: { type: [String, Object], required: false, default: undefined },
    icon: { type: Object, required: false, default: () => ({ name: 'arrow-right', width: '16', height: '16', color: 'text-theme-quaternary' }) },
    textColor: { type: String, required: false, default: 'text-theme-button' },
    text: { type: String, required: false, default: '' },
    textCase: { type: String, required: false, default: 'big' },
    responsive: { type: Boolean, required: false, default: undefined },
  },
})
